import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import { LogoLink } from "components/headers/light.js";
import logo from "images/logo.png";
import data from './Category+Listings+Report+03-24-2021.tsv';
import { motion } from "framer-motion"
import styled from "styled-components"
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col sm:flex-row items-center justify-between`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const bannedAsin = ["195893067626"]
// const humanFriendlyCat = {
//   "cameraotheraccessories":"Camera & Photo Accessories"
// }

const categorise = {
  "Sport": ["cameraotheraccessories", "avfurniture", "sportinggoods", "recreationball", "stickerdecal"],
  "Home": ["bedandbath"]
}

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

// const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
// const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

class EcommercePage1 extends React.Component {
  constructor(props) {
      super(props);
      this.state = {data: {}, tabs: new Set(), activeTab: ""}
      //setTimeout(()=> console.log(this.state), 5000)
  }

  componentDidMount() { this.getCsvData() }

  getTitle = (item) => {
    if(item[5]) return item[5]
    //if(item[30]) return `${item[30].replace(/<[^>]*>?/gm, '').substring(0,50)} ....`
  }

  openProduct = (item) => {
    this.props.history.push(`/item/${item[3]}`)
    //window.open(`https://www.amazon.com/dp/${item[3]}`, "_blank") //to open new page
  }

  fetchCsv = () => {
    return fetch(data).then((response) => {
      let reader = response.body.getReader()
      let decoder = new TextDecoder('utf-8')
      return reader.read().then((result) => decoder.decode(result.value))
    })
  }
  validProduct = (x) => {
    return x[3] && bannedAsin.indexOf(x[3]) < 0 && x[1].length > 5 && x[2] && x[5]
  }
  setActiveTab = (activeTab) => {
    this.setState({activeTab})
  }

  getData = (result) => {
    console.log("Setting result is ", result)
    this.setState({data: result.data})
  }

  getNormCat = (awsCat) => {
    return Object.keys(categorise).find(k=>  categorise[k].indexOf(awsCat) >= 0)
  }

  getCsvData = async () => {
    let csvData = await this.fetchCsv();
    const tabs = new Set()
    csvData = csvData.split("\n").slice(2)
    const header = csvData[0].split("\t").map((k,i) => {
      return {[k]:i}
    })
    const data = {...categorise}
    csvData.slice(1).forEach(x => {
      const row = x.split("\t")
      const normCat = this.getNormCat(row[0])
      if(!(normCat in data)) {
        data[normCat] = []
      }
      data[normCat].push(row)
    })

    this.setState({header, data, tabs})
  }

  render() {
    var {activeTab} = this.state
    const cats = Object.keys(categorise);
    if(!activeTab) activeTab = cats[0]
    return (
      <AnimationRevealPage disabled>
        <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
          <Content2Xl>
            <NavRow>
              <LogoLink href="/">
                <img style={{width: "100px"}} src={logo} alt="Eagles"/>
              </LogoLink>
            </NavRow>
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>Checkout our <HighlightedText>products.</HighlightedText></Header>
          <TabsControl>
            {cats && cats.map((tabName, index) => (
              <TabControl key={index} active={tabName} onClick={() => this.setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {cats && cats.map((cat, index) => (
          <TabContent key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === cat ? "current" : "hidden"}
            animate={activeTab === cat ? "current" : "hidden"}
          >
            {this.state.data[cat] && this.state.data[cat].filter(x=> this.validProduct(x)).map((x, index) => (
              <CardContainer key={index}>
                <Card className="group" href={x.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={`/images/${x[3]}_0.jpg`}>
                    <CardRatingContainer>
                      <CardRating>
                        <img alt={` ASIN: ${x[3]}`} src={logo} width="24" style={{position: "absolute", top:"4px", left: "-5px"}}/>{` ASIN: ${x[3]}`}
                      </CardRating>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton onClick={() => this.openProduct(x)}>Buy Now</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>Brand: {x[2]}</CardTitle>
                    <CardContent>{this.getTitle(x)}</CardContent>
                    {/* <CardPrice>{`${x[520]} ${x[521]}`}</CardPrice> */}
                  </CardText>
                  <CardButton style={{width: "100%"}} onClick={() => this.openProduct(x)}>Buy Now</CardButton>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
          </Content2Xl>
        </Container>
      </AnimationRevealPage>
    )
  }
}

export default EcommercePage1